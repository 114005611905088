import React from 'react';

export default class MoreOptionsIcon extends React.Component {
  /* Template

    this.props = {
      id: '',
      className: '',
      menuItems: [{
        text: '',
        id: '',
        className: '',
        testingId: '',
        onClick: function
      }]
      propertyPageLeftPanel: 'true' // This prop is for 3-dot menu in the left panel in the properties page. The menus are properties panel and the unit panel
    }

  */

  componentDidMount() {
    var menuButton = this.parentElement.querySelector('.pm-menu-button');
    var menuList = this.parentElement.querySelector('.pm-invoice-list');

    var menu = new mdc.menu.MDCMenu(menuList);
    menuButton.addEventListener('click', function () {
      menu.open = !menu.open;
    });

    // Added by mogul but it is not finished and does not work properly
    // var menu = new mdc.menu.MDCMenu(menuList);
    // menuButton.addEventListener('click', function() {
    //   menu.open = !menu.open;
    //   this.parentNode.parentNode.classList.toggle('pm-menu-open');
    // });
    // menuButton.addEventListener("MDCMenu:close", function(evt) {
    //   console.log(this);
    //   menu.parentNode.parentNode.classList.toggle('pm-menu-open');
    // });
  }

  render() {
    var menuItems = this.props.menuItems.map(function (menuItem, idx) {
      let menuItemHtml;
      if (menuItem.toggleSwitch) {
        menuItemHtml = (
          <li
            key={idx}
            id={menuItem.id || null}
            data-testing-id={menuItem.testingId || null}
            className={
              'mdc-list-item' +
              (menuItem.className != null ? ' ' + menuItem.className : '')
            }
            onClick={menuItem.onClick}
          >
            {menuItem.text}
            <div
              className="mdc-switch"
              style={{ marginLeft: '1.5625rem', top: '-0.125rem' }}
            >
              <input
                type="checkbox"
                className="mdc-switch__native-control"
                defaultChecked={menuItem.toggleSwitchSelected || false}
              />
              <div className="mdc-switch__background">
                <div className="mdc-switch__knob"></div>
              </div>
            </div>
          </li>
        );
      } else {
        menuItemHtml = (
          <li
            key={idx}
            id={menuItem.id || null}
            data-testing-id={menuItem.testingId || null}
            className={
              'mdc-list-item' +
              (menuItem.className != null ? ' ' + menuItem.className : '')
            }
            role="menuitem"
            onClick={menuItem.onClick}
            data-action-url={menuItem.actionUrl || ''}
            data-field-text={menuItem.fieldText || ''}
          >
            {menuItem.text}
          </li>
        );
      }
      return menuItemHtml;
    });

    let menuClassNames =
      'pm-invoice-list mdc-menu mdc-menu--open-from-top-right';
    if (this.props.menuClassNames != null)
      menuClassNames = menuClassNames + ' ' + this.props.menuClassNames;

    let menuDivStyle = {};
    if (this.props.propertyPageLeftPanel) {
      menuDivStyle = {
        // position: "absolute",
        // right: "39.0.3125rem",
        // width: "1.3125rem",
        // textAlign: "center",
        // height: "3rem",
        // display: "-webkit-box",
        // display: "-ms-flexbox",
        // display: "flex",
        // WebkitBoxAlign: "center",
        // msFlexAlign: "center",
        // alignItems: "center",
        // WebkitBoxPack: "center",
        // msFlexPack: "center",
        // justifyContent: "center",
        // zIndex: "0",
        // top: "0.125rem",
        // marginLeft: "0rem",
        // marginRight: "0rem"
      };
    } else if (this.props.propertyPageLeftPanelUnits) {
      menuDivStyle = {
        // position: "absolute",
        // right: "10.0.3125rem",
        // width: "1.3125rem",
        // textAlign: "center",
        // height: "3rem",
        // display: "-webkit-box",
        // display: "-ms-flexbox",
        // display: "flex",
        // WebkitBoxAlign: "center",
        // msFlexAlign: "center",
        // alignItems: "center",
        // WebkitBoxPack: "center",
        // msFlexPack: "center",
        // justifyContent: "center",
        // zIndex: "0",
        // top: "0.125rem",
        // marginLeft: "0rem",
        // marginRight: "0rem"
      };
    } else if (this.props.userRolesModal) {
      menuDivStyle = {
        top: '0rem',
      };
    }

    return (
      <div
        id={'more-options-icon-' + this.props.id}
        className={'mdc-menu-anchor ' + (this.props.className || '')}
        ref={(elem) => {
          this.parentElement = elem;
        }}
        style={menuDivStyle}
      >
        <button
          id="pm-sidebarmenu-button_1"
          type="button"
          className="pm-menu-button mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--blue1"
          data-mdc-auto-init="MDCRipple"
        >
          <i className="mdi mdi-dots-vertical"></i>
        </button>
        <div
          id="pm-sidebarmenu-list_1"
          className={menuClassNames}
          tabIndex="-1"
          style={{ minHeight: 'unset' }}
        >
          <ul
            style={{ padding: this.props.menuItems.length > 1 ? '' : '0' }}
            className="mdc-menu__items mdc-list"
            role="menu"
            aria-hidden="true"
          >
            {menuItems}
          </ul>
        </div>
      </div>
    );
  }
}
